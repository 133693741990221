import { PRODUCT_MODULE_URL } from "./product.module-config";

interface MenuItemInterface {
  key: string;
  label: string;
  onClick?: () => void;
}

// Tạo list menu con demo
export const LIST_MENU_PRODUCT: MenuItemInterface[] = [
  {
    key: PRODUCT_MODULE_URL + "/create",
    label: "Thêm Sản phẩm",
  },
  {
    key: PRODUCT_MODULE_URL,
    label: "Ds Sản phẩm",
  },
  {
    key: "/sales/category",
    label: "Danh mục",
  },
];

export default LIST_MENU_PRODUCT;
