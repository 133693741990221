import ICT24hAppUI, { AppConfigContextInterface } from "@hoa00/i24-core-ui";
import { appRouters } from "./AppRouters";

const ExtraLoginComponent = () => {
  return (
    <a href="#" target="_blank" rel="noreferrer">
      #
    </a>
  );
};

function App() {
  const appConfigContextInit: AppConfigContextInterface = {
    localStorageKey: process.env.REACT_APP_RF_TOKEN_LOCAL_NAME!,
    authAPIEndpoint: process.env.REACT_APP_API_ENDPOINT + "v1",

    loginPage: {
      title: "Vui lòng đăng nhập để tiếp tục",
      extra: <ExtraLoginComponent />,
      description: "Start a new day",
    },
  };

  return (
    <ICT24hAppUI appRouters={appRouters} appConfig={appConfigContextInit} />
  );
}

export default App;
