import type { FC } from "react";
import { useState, useEffect } from "react";
import { MenuProps } from "antd";
import { Menu } from "antd";
import {
  UserOutlined,
  CodeSandboxOutlined,
  BorderlessTableOutlined,
  DollarOutlined,
  ShoppingCartOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import LIST_MENU_PRODUCT from "../../modules/product/constant/product.left-menu-items";
import LIST_MENU_ORDER from "../../modules/order/constant/order.left-menu-items";
import LIST_MENU_ORDER_TABLE from "../../modules/order-table/constant/product.left-menu-items";
import LIST_MENU_CONTACT from "../../modules/contact/constant/contact.left-menu-items";
import LIST_MENU_CASH_FLOW from "../../modules/cash-flow/constant/contact.left-menu-items";

const LIST_MENU_OPENED = ["order"];

const SalesLeftMenu: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [selectedKey, setSelectedKey] = useState<string>("");

  // Tự highlight menu đúng với đường dẫn
  useEffect(() => {
    if (pathname) {
      setSelectedKey(pathname);
    }
  }, [pathname]);

  // tạo menu fake mẫu
  const items2: MenuProps["items"] = [
    {
      key: "/home",
      label: "Trang chủ",
      icon: <HomeOutlined />,
      onClick: () => {
        navigate("/home");
      },
    },
  ];

  // Thêm menu Order
  // items2.push({
  //   key: "order",
  //   icon: <ShoppingCartOutlined />,
  //   label: "Đơn hàng",
  //   children: LIST_MENU_ORDER.map((menuItem) => {
  //     menuItem.onClick = () => {
  //       navigate(menuItem.key);
  //     };
  //     return menuItem;
  //   }),
  // });

  // Thêm menu Product
  items2.push({
    key: "product",
    icon: <CodeSandboxOutlined />,
    label: "Sản phẩm",
    children: LIST_MENU_PRODUCT.map((menuItem) => {
      menuItem.onClick = () => {
        navigate(menuItem.key);
      };
      return menuItem;
    }),
  });

  // Thêm menu ORDER TABLE
  items2.push({
    key: "order-table",
    icon: <BorderlessTableOutlined />,
    label: "Bàn bán hàng",
    children: LIST_MENU_ORDER_TABLE.map((menuItem) => {
      menuItem.onClick = () => {
        navigate(menuItem.key);
      };
      return menuItem;
    }),
  });

  // Thêm menu CONTACT
  items2.push({
    key: "contact",
    icon: <UserOutlined />,
    label: "Liên hệ / Đối tác",
    children: LIST_MENU_CONTACT.map((menuItem) => {
      menuItem.onClick = () => {
        navigate(menuItem.key);
      };
      return menuItem;
    }),
  });

  // Thêm menu CONTACT
  items2.push({
    key: "cash-flow",
    icon: <DollarOutlined />,
    label: "QL Dòng tiền",
    children: LIST_MENU_CASH_FLOW.map((menuItem) => {
      menuItem.onClick = () => {
        navigate(menuItem.key);
      };
      return menuItem;
    }),
  });

  // Thêm list menu = chương trình
  // items2.push({
  //   key: "other1",
  //   icon: <UserOutlined />,
  //   label: "Other 1",
  //   children: LIST_MENU.map((menuItem) => {
  //     menuItem.onClick = () => {
  //       navigate(menuItem.key);
  //     };
  //     return menuItem;
  //   }),
  // });

  return (
    <>
      {/* <Button
        style={{ width: "100%" }}
        type="primary"
        size="large"
        icon={<ShoppingCartOutlined />}
        onClick={() => {
          navigate(ORDER_MODULE_URL + "/create");
        }}
      >
        POS
      </Button> */}
      <Menu
        mode="inline"
        defaultOpenKeys={LIST_MENU_OPENED}
        style={{ height: "100%", minHeight: "89vh", borderRight: 0 }}
        items={items2}
        selectedKeys={[selectedKey]}
      />
    </>
  );
};

export default SalesLeftMenu;
