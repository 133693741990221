import { HeaderLayoutConfig, SiderbarLeftLayout } from "@hoa00/i24-core-ui";
import { Card, Divider } from "antd";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";
import SalesLeftMenu from "../sales/components/LeftMenu/SalesLeftMenu";

import { LinkOutlined } from "@ant-design/icons";

export const homeLayoutConfig: HeaderLayoutConfig = {
  appName: "Core HRM: Sales",
  backgroundColor: "#1677ff",
  // color: "gray",
};

const apps = [
  {
    title: "Báo cáo dòng tiền",
    path: "/sales/cash-flow",
  },
  {
    title: "Sản phẩm",
    path: "/sales/product",
  },
  {
    title: "Danh mục sản phẩm",
    path: "/sales/category",
  },
  {
    title: "Đối tác / liên hệ",
    path: "/sales/contact",
  },
  {
    title: "Sổ quỹ",
    path: "/sales/cash-flow/cash-book",
  },
  {
    title: "Phiếu chi",
    path: "/sales/cash-flow/cash-out",
  },
  {
    title: "QL bàn tại cửa hàng",
    path: "/sales/order-table",
  },
];

const appsMobile = [
  {
    title: "Tạo đơn hàng",
    path: "/order-mobile/create",
  },
  {
    title: "Danh sách đơn hàng",
    path: "/order-mobile",
  },
];

const appStyle: React.CSSProperties = {
  height: 40,
  cursor: "pointer",
  backgroundColor: "#f6f6f6",
  margin: 2,
  padding: 8,
  verticalAlign: "middle",
  borderRadius: 8,
  fontWeight: "semibold",
};

export const HomePage: FC = () => {
  const nav = useNavigate();

  return (
    <SiderbarLeftLayout
      menu={<SalesLeftMenu />}
      headerConfig={homeLayoutConfig}
      footer="Nothing to fear"
    >
      <div>
        <Card>
          {/* <Button
            type="primary"
            onClick={() => {
              nav("/order-mobile");
            }}
          >
            Đơn hàng (dành cho di động)
          </Button> */}
          <Divider orientation="left">Ứng dụng cho ĐT di động</Divider>
          {appsMobile.map((app) => (
            <div key={app.title} onClick={() => nav(app.path)} style={appStyle}>
              <LinkOutlined /> {app.title}
            </div>
          ))}
          <Divider orientation="left">Các ứng dụng khác</Divider>
          {apps.map((app) => (
            <div key={app.title} onClick={() => nav(app.path)} style={appStyle}>
              <LinkOutlined /> {app.title}
            </div>
          ))}
        </Card>
      </div>
    </SiderbarLeftLayout>
  );
};
