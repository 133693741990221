import { LoadingLazyComponents, NotFoundPage } from "@hoa00/i24-core-ui";
import { FC, lazy } from "react";
import { Routes, Route } from "react-router-dom";

const ListPage = lazy(() => import("../pages/ListOrderMobile"));
const CreatePage = lazy(() => import("../pages/CreateOrderMobile"));
const UpdatePage = lazy(() => import("../pages/UpdateOrderMobile"));

interface IProps {}

const OrderMobileRouters: FC<IProps> = (props) => {
  return (
    <Routes>
      <Route
        path="/"
        element={<LoadingLazyComponents children={<ListPage />} />}
      />
      <Route
        path="/create"
        element={<LoadingLazyComponents children={<CreatePage />} />}
      />
      <Route
        path="/update/:itemId"
        element={<LoadingLazyComponents children={<UpdatePage />} />}
      />

      {/* Fallback Not found pages */}
      <Route
        path="*"
        element={<LoadingLazyComponents children={<NotFoundPage />} />}
      />
    </Routes>
  );
};

export default OrderMobileRouters;
