import { ORDER_TABLE_MODULE_URL } from "./order-table.module-config";

interface MenuItemInterface {
  key: string;
  label: string;
  onClick?: () => void;
}

// Tạo list menu con demo
export const LIST_MENU_ORDER_TABLE: MenuItemInterface[] = [
  {
    key: ORDER_TABLE_MODULE_URL,
    label: "Ds Bàn bán",
  },
  {
    key: ORDER_TABLE_MODULE_URL + "/create",
    label: "Thêm Bàn",
  },
];

export default LIST_MENU_ORDER_TABLE;
