import { SiderbarLeftLayout } from "@hoa00/i24-core-ui";
import type { FC } from "react";
import SalesLeftMenu from "./components/LeftMenu/SalesLeftMenu";
import { salesLayoutConfig } from "./config/sales.layout";
import SalesRouters from "./config/SalesRouters";

export const SalesHome: FC = () => {
  return (
    <SiderbarLeftLayout
      menu={<SalesLeftMenu />}
      headerConfig={salesLayoutConfig}
      contentCss={{ padding: 5 }}
      footer=" "
    >
      <SalesRouters />
    </SiderbarLeftLayout>
  );
};
