import { LoadingLazyComponents, NotFoundPage } from "@hoa00/i24-core-ui";
import { FC, lazy } from "react";
import { Route, Routes } from "react-router-dom";

const ListPage = lazy(() => import("./pages/CashFlowList"));
const Create = lazy(() => import("./pages/ContactCreate"));
const UpdatePage = lazy(() => import("./pages/ContactUpdate"));
const CashBookRouters = lazy(
  () => import("./modules/cash-book/CashBookRouters")
);
const CashOutTagRouters = lazy(
  () => import("./modules/cash-out/modules/cash-out-tag/CashOutTagRouters")
);
const CashOutCategoryRouters = lazy(
  () =>
    import(
      "./modules/cash-out/modules/cash-out-category/CashOutCategoryRouters"
    )
);
const CashOutRouters = lazy(() => import("./modules/cash-out/CashOutRouters"));

const CashFlowRouters: FC = () => {
  return (
    <Routes>
      <Route
        path="/cash-book/*"
        element={<LoadingLazyComponents children={<CashBookRouters />} />}
      />
      <Route
        path="/cash-out/*"
        element={<LoadingLazyComponents children={<CashOutRouters />} />}
      />
      <Route
        path="/cash-out-tag/*"
        element={<LoadingLazyComponents children={<CashOutTagRouters />} />}
      />
      <Route
        path="/cash-out-category/*"
        element={
          <LoadingLazyComponents children={<CashOutCategoryRouters />} />
        }
      />
      <Route
        path="/"
        element={<LoadingLazyComponents children={<ListPage />} />}
      />
      <Route
        path="/create"
        element={<LoadingLazyComponents children={<Create />} />}
      />
      <Route
        path="/update/:itemId"
        element={<LoadingLazyComponents children={<UpdatePage />} />}
      />

      {/* Fallback Not found pages */}
      <Route
        path="*"
        element={<LoadingLazyComponents children={<NotFoundPage />} />}
      />
    </Routes>
  );
};

export default CashFlowRouters;
