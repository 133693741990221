import { CONTACT_MODULE_URL } from "./contact.module-config";

interface MenuItemInterface {
  key: string;
  label: string;
  onClick?: () => void;
}

// Tạo list menu con demo
export const LIST_MENU_CONTACT: MenuItemInterface[] = [
  {
    key: CONTACT_MODULE_URL,
    label: "Liên hệ",
  },
  {
    key: CONTACT_MODULE_URL + "/type",
    label: "Loại liên hệ",
  },
];

export default LIST_MENU_CONTACT;
