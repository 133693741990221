import { LoadingLazyComponents, NotFoundPage } from "@hoa00/i24-core-ui";
import { FC, lazy } from "react";
import { Route, Routes } from "react-router-dom";

const ListPage = lazy(() => import("./pages/OrderList"));
const Create = lazy(() => import("./pages/OrderCreate"));
const CreateMultiple = lazy(() => import("./pages/OrderCreateMultiple"));
const UpdatePage = lazy(() => import("./pages/OrderUpdate"));

const SalesOrderRouters: FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<LoadingLazyComponents children={<ListPage />} />}
      />
      <Route
        path="/create"
        element={<LoadingLazyComponents children={<Create />} />}
      />
      <Route
        path="/creates"
        element={<LoadingLazyComponents children={<CreateMultiple />} />}
      />
      <Route
        path="/update/:itemId"
        element={<LoadingLazyComponents children={<UpdatePage />} />}
      />

      {/* Fallback Not found pages */}
      <Route
        path="*"
        element={<LoadingLazyComponents children={<NotFoundPage />} />}
      />
    </Routes>
  );
};

export default SalesOrderRouters;
