import { SiderbarLeftLayout } from "@hoa00/i24-core-ui";
import ConfigLeftMenu from "../components/Config.LeftMenu";
import ConfigRouters from "../components/Config.Routers";
import { configLayoutConfig } from "../services/config.layout-config";

export const ConfigHomePage: React.FC = () => {
  return (
    <SiderbarLeftLayout
      menu={<ConfigLeftMenu />}
      headerConfig={configLayoutConfig}
    >
      <ConfigRouters />
    </SiderbarLeftLayout>
  );
};
