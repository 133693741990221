import { Routes, Route } from "react-router-dom";
import type { FC } from "react";
import { lazy } from "react";
import { NotFoundPage, LoadingLazyComponents } from "@hoa00/i24-core-ui";
import SalesCategoryRouters from "../modules/category/CategoryRouters";
import SalesProductRouters from "../modules/product/ProductRouters";
import SalesOrderRouters from "../modules/order/OrderRouters";
import SalesOrderTableRouters from "../modules/order-table/OrderTableRouters";
import ContactRouters from "../modules/contact/ContactRouters";
import CashFlowRouters from "../modules/cash-flow/CashFlowRouters";

const Dashboard = lazy(() => import("../pages/Dashboard"));
const ListDemo2 = lazy(() => import("../pages/ListDemo2"));

const SalesRouters: FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<LoadingLazyComponents children={<Dashboard />} />}
      />
      <Route
        path="/list"
        element={<LoadingLazyComponents children={<ListDemo2 />} />}
      />
      <Route
        path="/category/*"
        element={<LoadingLazyComponents children={<SalesCategoryRouters />} />}
      />
      <Route
        path="/product/*"
        element={<LoadingLazyComponents children={<SalesProductRouters />} />}
      />
      <Route
        path="/order/*"
        element={<LoadingLazyComponents children={<SalesOrderRouters />} />}
      />
      <Route
        path="/order-table/*"
        element={
          <LoadingLazyComponents children={<SalesOrderTableRouters />} />
        }
      />
      <Route
        path="/contact/*"
        element={<LoadingLazyComponents children={<ContactRouters />} />}
      />
      <Route
        path="/cash-flow/*"
        element={<LoadingLazyComponents children={<CashFlowRouters />} />}
      />

      {/* Fallback Not found pages */}
      <Route
        path="*"
        element={<LoadingLazyComponents children={<NotFoundPage />} />}
      />
    </Routes>
  );
};

export default SalesRouters;
