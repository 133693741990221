import { Routes, Route } from "react-router-dom";
import type { FC } from "react";
import { lazy } from "react";
import { NotFoundPage, LoadingLazyComponents } from "@hoa00/i24-core-ui";

const ConfigDashboardPage = lazy(() => import("../pages/Config.Dashboard"));
const ConfigListPage = lazy(() => import("../pages/Config.List"));

const ConfigRouters: FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<LoadingLazyComponents children={<ConfigDashboardPage />} />}
      />
      <Route
        path="/list"
        element={<LoadingLazyComponents children={<ConfigListPage />} />}
      />

      {/* Fallback Not found pages */}
      <Route
        path="*"
        element={<LoadingLazyComponents children={<NotFoundPage />} />}
      />
    </Routes>
  );
};

export default ConfigRouters;
