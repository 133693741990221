import { CASH_BOOK_MODULE_URL } from "../modules/cash-book/constant/cash-book.module-config";
import { CASH_OUT_MODULE_URL } from "../modules/cash-out/constant/cash-out.module-config";
import { CASH_OUT_CATEGORY_MODULE_URL } from "../modules/cash-out/modules/cash-out-category/constant/cash-out-category.module-config";
import { CASH_OUT_TAG_MODULE_URL } from "../modules/cash-out/modules/cash-out-tag/constant/cash-out-tag.module-config";
import { CASH_FLOW_MODULE_URL } from "./contact.module-config";

interface MenuItemInterface {
  key: string;
  label: string;
  onClick?: () => void;
}

// Tạo list menu con demo
export const LIST_MENU_CASH_FLOW: MenuItemInterface[] = [
  {
    key: CASH_FLOW_MODULE_URL,
    label: "Báo cáo Dòng tiền",
  },
  {
    key: CASH_BOOK_MODULE_URL,
    label: "Sổ quỹ",
  },
  {
    key: CASH_OUT_MODULE_URL,
    label: "Phiếu chi",
  },
  {
    key: CASH_OUT_CATEGORY_MODULE_URL,
    label: "Danh mục Phiếu chi",
  },
  {
    key: CASH_OUT_TAG_MODULE_URL,
    label: "Thẻ Phiếu chi",
  },
];

export default LIST_MENU_CASH_FLOW;
