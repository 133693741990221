import type { FC } from "react";
import { createElement, useState, useEffect } from "react";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { LIST_MENU } from "../services/config.menu-items";

const ConfigLeftMenu: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [selectedKey, setSelectedKey] = useState<string>("");

  // Tự highlight menu đúng với đường dẫn
  useEffect(() => {
    if (pathname) {
      setSelectedKey(pathname);
    }
  }, [pathname]);

  // tạo menu fake mẫu
  const items2: MenuProps["items"] = [
    UserOutlined,
    LaptopOutlined,
    NotificationOutlined,
  ].map((icon, index) => {
    const key = String(index + 1);

    return {
      key: `sub${key}`,
      icon: createElement(icon),
      label: `subnav ${key}`,

      children: new Array(4).fill(null).map((_, j) => {
        const subKey = index * 4 + j + 1;
        return {
          key: subKey,
          label: `option${subKey}`,
        };
      }),
    };
  });

  // Thêm list menu = chương trình
  items2.unshift({
    key: "other1",
    icon: <UserOutlined />,
    label: "Other 1",
    children: LIST_MENU.map((menuItem) => {
      menuItem.onClick = () => {
        navigate(menuItem.key);
      };
      return menuItem;
    }),
  });

  return (
    <Menu
      mode="inline"
      defaultOpenKeys={["other1"]}
      style={{ height: "100%", minHeight: "89vh", borderRight: 0 }}
      items={items2}
      selectedKeys={[selectedKey]}
    />
  );
};

export default ConfigLeftMenu;
