import { AppRouterItem } from "@hoa00/i24-core-ui";
import { Navigate } from "react-router-dom";
import { ConfigHomePage } from "./modules/config/pages/Config.Home";
import { HomePage } from "./modules/home/Home";
import { SalesHome } from "./modules/sales/sales.home";
import OrderCreateMobile from "./modules/sales/modules/order/pages/OrderCreateMobile";

export const appRouters: AppRouterItem[] = [
  {
    path: "/home/",
    element: <HomePage />,
  },

  {
    path: "/config/*",
    element: <ConfigHomePage />,
  },
  {
    path: "/sales/*",
    element: <SalesHome />,
  },
  {
    path: "/order-mobile/*",
    element: <OrderCreateMobile />,
  },
];
