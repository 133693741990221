import { LoadingLazyComponents, NotFoundPage } from "@hoa00/i24-core-ui";
import { FC, lazy } from "react";
import { Route, Routes } from "react-router-dom";

const Dashboard = lazy(() => import("./pages/CategoryList"));
const Create = lazy(() => import("./pages/CategoryCreate"));
const UpdatePage = lazy(() => import("./pages/CategoryUpdate"));

const SalesCategoryRouters: FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<LoadingLazyComponents children={<Dashboard />} />}
      />
      <Route
        path="/create"
        element={<LoadingLazyComponents children={<Create />} />}
      />
      <Route
        path="/update/:itemId"
        element={<LoadingLazyComponents children={<UpdatePage />} />}
      />

      {/* Fallback Not found pages */}
      <Route
        path="*"
        element={<LoadingLazyComponents children={<NotFoundPage />} />}
      />
    </Routes>
  );
};

export default SalesCategoryRouters;
